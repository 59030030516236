import { template as template_18df1e6ca0b04320b6ed90ac01bfd4b5 } from "@ember/template-compiler";
const FKLabel = template_18df1e6ca0b04320b6ed90ac01bfd4b5(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
