import { template as template_b01a9824f30044d5be156ab7691c76da } from "@ember/template-compiler";
const WelcomeHeader = template_b01a9824f30044d5be156ab7691c76da(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
