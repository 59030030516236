import { template as template_9739b46aa9054af393c06f27be09dbcd } from "@ember/template-compiler";
const FKText = template_9739b46aa9054af393c06f27be09dbcd(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
